@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap);
/* Fonts */

/* CSS Reset */

html {
  height: 100%;
  font-family: Arial, sans-serif;
}
body { 
  margin: 0; 
  height: 100%;
  min-height: 100%;
  background-color: #eeeeee;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}


/* Visualizations */

.line { 
  fill: none;
  stroke-width: 2.5;
}
.dot {
  stroke: #fff;
}
.dot:hover, .dataRect:hover {
  cursor: pointer;
}
.label {
  font-size: 13px;
  font-weight: 400;
}
.tick {
  font-size: 12px;
  color: #231F20;
}
.axis {
  color: #231F20;
}
text { 
  color: #231F20;
}
.graph-title {
  font-size: 20px;
  font-weight: 500;
  color: #231F20;
}
#LeaderboardTable h6 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #231F20 !important;
  line-height: 1em !important;
  text-align: left;
}
#harperX line, #harperX path, #harperY line, #harperY path {
  display: none;
}
#HarperGraph svg {
  margin-top: 5px !important;
}
.tooltip {
  padding: 10px;
  border: 2px solid #231f20;
  border-radius: 10px;
  max-width: 150px;
  position: absolute;
  font-size: 12px;
  color: #231F20;
  background-color: rgba(255, 255, 255, 0.7);
}
.module-content {
  text-align: center;
  position: relative;
}

.newTooltip {
  padding: 10px;
  border: 2px solid #231f20;
  border-radius: 10px;
  font-size: 12px;
  color: #231F20;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left !important;
  display: inline-block;
  position: fixed;
}

.tooltipLine {
  white-space: nowrap;
}

/* General Layout */

#logo {
  width: 250px;
  height: auto;
  margin: 14px;
  float: left;
}
#top-nav {
  float: left;
  margin-left: 100px;
}
#top-nav a {
  font-size: 16px;
  color: #231f20;
  margin-right: 32px;
  text-decoration: none;
}

/* Overview Page */

#LeaderboardTable thead th {
  color: #231F20 !important;
  font-weight: 500;
}

/* Modal */

.ReactModal__Overlay {
  z-index: 10000;
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
}
.ReactModal__Overlay--after-open{
  opacity: 1;
}
.ReactModal__Overlay--before-close{
  opacity: 0;
}
.postContainer {
  float: left;
  margin-top: -10px;
}
.postInfo {
  margin-top: 20px;
  display: block;
}
.postInfoLabel {
  width: 215px;
  font-weight: bold;
  text-align: right;
  margin-right: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
.post-row {
  display: block;
  height: 520px;
  clear: both;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 3px solid #eeeeee;
}

.datePicker {
  text-align: center;
  z-index: 10000 !important;
}
.DateRangePicker_picker {
  z-index: 10000 !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: rgba(40, 185, 209) !important;
  border: 1px double rgba(40, 185, 209) !important;
}
.CalendarDay__selected_span {
  background: rgba(40, 185, 209, 0.5) !important;
  border: 1px double rgba(40, 185, 209) !important;
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background: rgba(40, 185, 209, 0.75) !important;
  border: 1px double rgba(40, 185, 209) !important;  
}

.MuiSnackbar-root {
  z-index: 10000 !important;
}

svg {
  overflow: visible;
}
